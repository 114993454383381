.container {
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
  background-color: #EFEDE7;

  .block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
    font-family: "P6", sans-serif;
    text-align: center;
    letter-spacing: 0.08px;
    color: #313131;
  }

  span {
    font-family: "Roboto4", sans-serif;
    font-size: 12px;
    line-height: 170%;
    letter-spacing: 0.08px;
    color: #696974;
  }

  svg {
    fill: #353431;
  }

  a {
    display: flex;
    gap: 6px;
    align-items: center;
    border: 1px solid #DAD6D3;
    border-radius: 7px;
    padding: 6px;
    font-family: "P6", sans-serif;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #313131;
  }
}

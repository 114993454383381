@import "./root", "./fonts", "./reset.css";

html, body{
    width: 100%;
}

body {
    font-family: "P5", sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #353431;
}

.popup-arrow {
    display: none;
}

.Toastify__toast-container {
    max-width: 490px;
    width: auto;
}

.Toastify__toast {
    gap: 17px;
    display: flex;
    align-items: center;
    padding: 12px 17px 12px 12px;
    background: #FAF9F6;
    border-radius: 18px;

}

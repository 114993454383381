@font-face {
  font-family: "P5";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "P6";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Roboto4";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Roboto5";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Roboto7";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "SF4";
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "SF7";
  src: url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Mont6";
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  unicode-range: U+0400-04FF;
}
